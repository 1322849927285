import axios from 'axios'
/**
 * 文件导出下载
 * @param data
 * @param fileName
 * @param http
 * @param url
 */
export function download(data, fileName, http, url) {
  axios({
    method: http,
    data: data,
    url: url,
    responseType: "arraybuffer"
  }).then(res => {
    const data = res.data;
    let blob = new Blob([data], { type: res.headers["content-type"] });
    const url = window.URL.createObjectURL(blob);
    if (window.navigator.msSaveOrOpenBlob) { //兼容ie的下载
      try {
        window.navigator.msSaveBlob(blob, fileName);
      } catch (e) {
        console.log(e);
      }
    } else {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
}
